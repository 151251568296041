<script setup>

const doubleCheck = ref(null)

const router = useRouter()
const localeRoute = useLocaleRoute()

const { data: page } = await useAPIFetch(`router?path=${router.currentRoute.value.fullPath}`)

if(page.value) {
	// redirect
	if(page.value.redirect) {
		window.location.href = page.value.redirect;
	} else if(page.value.page) {
		let route = null
		if (page.value.page === 'Article') {
			route = localeRoute({ name: useFindPageEndpoint(page.value.page), params: { slug: page.value.id }})
		} else {
			route = localeRoute(useFindPageEndpoint(page.value.page))
		}

		if (route) {
			navigateTo(route.fullPath)
		}
	} else {
		doubleCheck.value = true
	}
} else {
	doubleCheck.value = true
}

const props = defineProps({
  error: Object
})

</script>

<template>
  <NuxtLayout>
    <div v-if="doubleCheck" class="container mb-10 xl:mb-20">
			<div class="xl:grid grid-cols-12 gap-8">
				<div class="col-span-6">
					<h2 class="text-red mb-8">{{ error.statusCode === 500 ? $t('500.title') : $t('404.title') }}</h2>
					<p class="mb-6 xl:mb-12 dark:text-light" v-html="error.statusCode === 500 ? $t('500.text') : $t('404.text')"></p>
					<Button to="/">{{ $t('error.button') }}</Button>
				</div>
			</div>
		</div>
  </NuxtLayout>
</template>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.2s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>