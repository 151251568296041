import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/app/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_adVF2uRka6 from "/app/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import components_client_ZB1W0EeH0E from "/app/node_modules/nuxt-swiper/dist/runtime/plugins/components.client.js";
import formkitPlugin_pZqjah0RUG from "/app/.nuxt/formkitPlugin.mjs";
import plugin_client_i8AMfKeYnY from "/app/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import clickOutside_ZB3Eg0wOVk from "/app/plugins/clickOutside.ts";
import event_bus_S6Iq1iGe5d from "/app/plugins/event-bus.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  plugin_adVF2uRka6,
  components_client_ZB1W0EeH0E,
  formkitPlugin_pZqjah0RUG,
  plugin_client_i8AMfKeYnY,
  i18n_yfWm7jX06p,
  clickOutside_ZB3Eg0wOVk,
  event_bus_S6Iq1iGe5d
]