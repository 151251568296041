<script setup>
const props = defineProps({
  to: {
    type: String,
  },
  type: {
    type: String,
    default: 'primary',
  },
  target: {
    type: Boolean,
  },
	isSmall: {
		type: Boolean,
		default: false
	},
	disabled: {
		type: Boolean,
		default: false
	}
})

const targetBlank = computed(() => {
  if (props.target) {
    return '_blank'
  } else {
    return ''
  }
})

let classes = ''

switch (props.type) {
  case 'gold':
    classes = 'text-light hover:text-dark dark:hover:text-light border-gold gold'
    break
	case 'lilac':
    classes = 'text-light hover:text-dark dark:hover:text-light border-lilac lilac'
    break
	case 'green':
    classes = 'text-light hover:text-dark dark:hover:text-light border-green green'
    break
	case 'transparent':
    classes = 'dark:text-light text-dark hover:text-light dark:hover:text-dark dark:border-light border-dark transparent'
    break
  default:
    classes = 'dark:text-light text-light hover:text-dark border-red red'
    break
}
</script>

<template>
  <NuxtLinkLocale
		v-if="!targetBlank"
    :to="to"
    class="button inline-flex px-8 xl:px-10 py-3 xl:py-3 fvs600 cursor-pointer leading-[110%] border-2"
    :class="[classes, isSmall ? 'text-sm' : 'text-sm xl:text-lg', disabled ? 'pointer-events-none opacity-50' : '']"
    :target="targetBlank"
  >
  <span>  
		<slot />
	</span>
  </NuxtLinkLocale>
	<a
		v-else
		:href="to"
		class="button inline-flex px-8 xl:px-10 py-3 xl:py-3 fvs600 cursor-pointer leading-[110%] border-2"
    :class="[classes, isSmall ? 'text-sm' : 'text-sm xl:text-lg']"
    :target="targetBlank"
	>
		<span>  
			<slot />
		</span>
	</a>
</template>

<style lang="scss" scoped>
.button {
	position: relative;
	white-space: nowrap;
	overflow: hidden;
	transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);

	span {
		position: relative;
	}

	&::before {
		position: absolute;
		content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
		transition: 0.3s cubic-bezier(0.7, 0, 0.2, 1);
	}

	&.red {
		&::before {
			background: theme('colors.red');
		}
	}

	&.gold {
		&::before {
			background: theme('colors.gold');
		}
	}

	&.lilac {
		&::before {
			background: theme('colors.lilac');
		}
	}

	&.green {
		&::before {
			background: theme('colors.green');
		}
	}

	&.transparent {
		&::before {
			transform: translate3d(0, -100%, 0);
			background: theme('colors.dark');
		}

		.dark &::before {
			background: theme('colors.light');
		}

		&:hover {
			&::before {
				transform: translate3d(0, 0, 0);
			}
		}
	}

  .arrow {
    transition: all 0.2s ease-out;
  }
  &:hover {

		&::before {
			transform: translate3d(0, -100%, 0);
		}

    .arrow {
      transform: translateX(10px);
    }
  }
}
</style>
