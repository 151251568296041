
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91slug_93msfiAekumQMeta } from "/app/pages/[slug].vue?macro=true";
import { default as _91audio_9302N6ZUb6qwMeta } from "/app/pages/album/[slug]/[audio].vue?macro=true";
import { default as indexATPCJ9gjA6Meta } from "/app/pages/album/[slug]/index.vue?macro=true";
import { default as _91slug_930HO97GfwrDMeta } from "/app/pages/article/[slug].vue?macro=true";
import { default as articlesNoJuYiawbIMeta } from "/app/pages/articles.vue?macro=true";
import { default as _91slug_931Kp94TPaydMeta } from "/app/pages/artist/[slug].vue?macro=true";
import { default as artistsQseuuCRpbWMeta } from "/app/pages/artists.vue?macro=true";
import { default as bookinglHCUFF9cEwMeta } from "/app/pages/booking.vue?macro=true";
import { default as careerGI2vQurlWVMeta } from "/app/pages/career.vue?macro=true";
import { default as contactV6oPJjs9RyMeta } from "/app/pages/contact.vue?macro=true";
import { default as for_45artistsnhIoLF0pS3Meta } from "/app/pages/for-artists.vue?macro=true";
import { default as for_45business3mYseLr2hmMeta } from "/app/pages/for-business.vue?macro=true";
import { default as for_45mediaN7DB4udgMEMeta } from "/app/pages/for-media.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as influencer_45marketing7sFUqUwKQvMeta } from "/app/pages/influencer-marketing.vue?macro=true";
import { default as license1oPxwXaJfhMeta } from "/app/pages/license.vue?macro=true";
import { default as new_45releaseslsDDKZvjRvMeta } from "/app/pages/new-releases.vue?macro=true";
import { default as newsletterVEF8u8KZkVMeta } from "/app/pages/newsletter.vue?macro=true";
import { default as _91slug_936a6TQjIHJdMeta } from "/app/pages/podcast/[slug].vue?macro=true";
import { default as podcastst13v3mOxj1Meta } from "/app/pages/podcasts.vue?macro=true";
import { default as prague_45storeOwNDlAV07hMeta } from "/app/pages/prague-store.vue?macro=true";
import { default as _91slug_93RRwt7iAgOVMeta } from "/app/pages/release/[slug].vue?macro=true";
import { default as story_45of_45supraphoni3THZIwHIWMeta } from "/app/pages/story-of-supraphon.vue?macro=true";
export default [
  {
    name: "slug___cs",
    path: "/:slug()",
    component: () => import("/app/pages/[slug].vue")
  },
  {
    name: "slug___en",
    path: "/en/:slug()",
    component: () => import("/app/pages/[slug].vue")
  },
  {
    name: "album-slug-audio___cs",
    path: "/alba/:slug()/:audio()",
    component: () => import("/app/pages/album/[slug]/[audio].vue")
  },
  {
    name: "album-slug-audio___en",
    path: "/en/albums/:slug()/:audio()",
    component: () => import("/app/pages/album/[slug]/[audio].vue")
  },
  {
    name: "album-slug___cs",
    path: "/album/:slug()",
    component: () => import("/app/pages/album/[slug]/index.vue")
  },
  {
    name: "album-slug___en",
    path: "/en/album/:slug()",
    component: () => import("/app/pages/album/[slug]/index.vue")
  },
  {
    name: "article-slug___cs",
    path: "/novinky/:slug()",
    component: () => import("/app/pages/article/[slug].vue")
  },
  {
    name: "article-slug___en",
    path: "/en/news/:slug()",
    component: () => import("/app/pages/article/[slug].vue")
  },
  {
    name: "articles___cs",
    path: "/novinky",
    component: () => import("/app/pages/articles.vue")
  },
  {
    name: "articles___en",
    path: "/en/news",
    component: () => import("/app/pages/articles.vue")
  },
  {
    name: "artist-slug___cs",
    path: "/umelci/:slug()",
    component: () => import("/app/pages/artist/[slug].vue")
  },
  {
    name: "artist-slug___en",
    path: "/en/artists/:slug()",
    component: () => import("/app/pages/artist/[slug].vue")
  },
  {
    name: "artists___cs",
    path: "/umelci",
    component: () => import("/app/pages/artists.vue")
  },
  {
    name: "artists___en",
    path: "/en/artists",
    component: () => import("/app/pages/artists.vue")
  },
  {
    name: "booking___cs",
    path: "/booking",
    component: () => import("/app/pages/booking.vue")
  },
  {
    name: "booking___en",
    path: "/en/booking",
    component: () => import("/app/pages/booking.vue")
  },
  {
    name: "career___cs",
    path: "/kariera",
    component: () => import("/app/pages/career.vue")
  },
  {
    name: "career___en",
    path: "/en/career",
    component: () => import("/app/pages/career.vue")
  },
  {
    name: "contact___cs",
    path: "/kontakty",
    component: () => import("/app/pages/contact.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact-us",
    component: () => import("/app/pages/contact.vue")
  },
  {
    name: "for-artists___cs",
    path: "/pro-umelce",
    component: () => import("/app/pages/for-artists.vue")
  },
  {
    name: "for-artists___en",
    path: "/en/for-artists",
    component: () => import("/app/pages/for-artists.vue")
  },
  {
    name: "for-business___cs",
    path: "/pro-obchodniky",
    component: () => import("/app/pages/for-business.vue")
  },
  {
    name: "for-business___en",
    path: "/en/for-retailers",
    component: () => import("/app/pages/for-business.vue")
  },
  {
    name: "for-media___cs",
    path: "/pro-media",
    component: () => import("/app/pages/for-media.vue")
  },
  {
    name: "for-media___en",
    path: "/en/for-media",
    component: () => import("/app/pages/for-media.vue")
  },
  {
    name: "index___cs",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "influencer-marketing___cs",
    path: "/influencer-marketing",
    component: () => import("/app/pages/influencer-marketing.vue")
  },
  {
    name: "influencer-marketing___en",
    path: "/en/influencer-marketing",
    component: () => import("/app/pages/influencer-marketing.vue")
  },
  {
    name: "license___cs",
    path: "/licence",
    component: () => import("/app/pages/license.vue")
  },
  {
    name: "license___en",
    path: "/en/licensing",
    component: () => import("/app/pages/license.vue")
  },
  {
    name: "new-releases___cs",
    path: "/prave-vychazi",
    component: () => import("/app/pages/new-releases.vue")
  },
  {
    name: "new-releases___en",
    path: "/en/new-releases",
    component: () => import("/app/pages/new-releases.vue")
  },
  {
    name: "newsletter___cs",
    path: "/newsletter",
    component: () => import("/app/pages/newsletter.vue")
  },
  {
    name: "newsletter___en",
    path: "/en/newsletter",
    component: () => import("/app/pages/newsletter.vue")
  },
  {
    name: "podcast-slug___cs",
    path: "/podcasty/:slug()",
    component: () => import("/app/pages/podcast/[slug].vue")
  },
  {
    name: "podcast-slug___en",
    path: "/en/podcasts/:slug()",
    component: () => import("/app/pages/podcast/[slug].vue")
  },
  {
    name: "podcasts___cs",
    path: "/podcasty",
    component: () => import("/app/pages/podcasts.vue")
  },
  {
    name: "podcasts___en",
    path: "/en/podcasts",
    component: () => import("/app/pages/podcasts.vue")
  },
  {
    name: "prague-store___cs",
    path: "/prodejna",
    component: () => import("/app/pages/prague-store.vue")
  },
  {
    name: "prague-store___en",
    path: "/en/prague-store",
    component: () => import("/app/pages/prague-store.vue")
  },
  {
    name: "release-slug___cs",
    path: "/prave-vychazi/:slug()",
    component: () => import("/app/pages/release/[slug].vue")
  },
  {
    name: "release-slug___en",
    path: "/en/new-releases/:slug()",
    component: () => import("/app/pages/release/[slug].vue")
  },
  {
    name: "story-of-supraphon___cs",
    path: "/pribeh-supraphonu",
    component: () => import("/app/pages/story-of-supraphon.vue")
  },
  {
    name: "story-of-supraphon___en",
    path: "/en/story-of-supraphon",
    component: () => import("/app/pages/story-of-supraphon.vue")
  }
]