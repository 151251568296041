
// @ts-nocheck


export const localeCodes =  [
  "cs",
  "en"
]

export const localeLoaders = {
  "cs": [{ key: "../locales/cs.js", load: () => import("../locales/cs.js?hash=86883749&locale=cs" /* webpackChunkName: "locale__app_locales_cs_js" */), cache: false }],
  "en": [{ key: "../locales/en.js", load: () => import("../locales/en.js?hash=bde023b2&locale=en" /* webpackChunkName: "locale__app_locales_en_js" */), cache: false }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "cs",
      "iso": "cs-CZ",
      "name": "Czech",
      "files": [
        "locales/cs.js"
      ]
    },
    {
      "code": "en",
      "iso": "en-US",
      "name": "English",
      "files": [
        "locales/en.js"
      ]
    }
  ],
  "defaultLocale": "cs",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "locales",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "index": {
      "cs": "/",
      "en": "/"
    },
    "album/[slug]": {
      "cs": "/alba/[slug]",
      "en": "/albums/[slug]"
    },
    "album/[slug]/[audio]": {
      "cs": "/alba/[slug]/[audio]",
      "en": "/albums/[slug]/[audio]"
    },
    "article/[slug]": {
      "cs": "/novinky/[slug]",
      "en": "/news/[slug]"
    },
    "artists": {
      "cs": "/umelci",
      "en": "/artists"
    },
    "artist/[slug]": {
      "cs": "/umelci/[slug]",
      "en": "/artists/[slug]"
    },
    "booking": {
      "cs": "/booking",
      "en": "/booking"
    },
    "career": {
      "cs": "/kariera",
      "en": "/career"
    },
    "contact": {
      "cs": "/kontakty",
      "en": "/contact-us"
    },
    "for-artists": {
      "cs": "/pro-umelce",
      "en": "/for-artists"
    },
    "for-media": {
      "cs": "/pro-media",
      "en": "/for-media"
    },
    "for-business": {
      "cs": "/pro-obchodniky",
      "en": "/for-retailers"
    },
    "influencer-marketing": {
      "cs": "/influencer-marketing",
      "en": "/influencer-marketing"
    },
    "license": {
      "cs": "/licence",
      "en": "/licensing"
    },
    "prague-store": {
      "cs": "/prodejna",
      "en": "/prague-store"
    },
    "articles": {
      "cs": "/novinky",
      "en": "/news"
    },
    "newsletter": {
      "cs": "/newsletter",
      "en": "/newsletter"
    },
    "new-releases": {
      "cs": "/prave-vychazi",
      "en": "/new-releases"
    },
    "podcasts": {
      "cs": "/podcasty",
      "en": "/podcasts"
    },
    "podcast/[slug]": {
      "cs": "/podcasty/[slug]",
      "en": "/podcasts/[slug]"
    },
    "release/[slug]": {
      "cs": "/prave-vychazi/[slug]",
      "en": "/new-releases/[slug]"
    },
    "story-of-supraphon": {
      "cs": "/pribeh-supraphonu",
      "en": "/story-of-supraphon"
    },
    "[slug]": {
      "cs": "/[slug]",
      "en": "/[slug]"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "cs",
    "iso": "cs-CZ",
    "name": "Czech",
    "files": [
      {
        "path": "locales/cs.js"
      }
    ]
  },
  {
    "code": "en",
    "iso": "en-US",
    "name": "English",
    "files": [
      {
        "path": "locales/en.js"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
