export const useAPIFetch = (url, params, options = {}) => {

	const { locale } = useI18n()

	const config = useRuntimeConfig();
	options.baseURL = locale.value === 'cs' ? config.public.baseURLCS : config.public.baseURLEN

	return useFetch(url, {
		...options,
		...params,
		credentials: 'include',
		onResponseError({ request, response, options }) {
			console.log('error')
		}
	})
}