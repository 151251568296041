const pages = [
	{ key: 'StoryOfSupraphon', value: 'story-of-supraphon' },
	{ key: 'License', value: 'license' },
	{ key: 'Musicpoint', value: 'prague-store' },
	{ key: 'ForArtists', value: 'for-artists' },
	{ key: 'ForBusiness', value: 'for-business' },
	{ key: 'Career', value: 'career' },
	{ key: 'Contact', value: 'contact' },
	{ key: 'ForMedia', value: 'for-media' },
	{ key: 'Booking', value: 'booking' },
	{ key: 'InfluencerMarketing', value: 'influencer-marketing' },
	{ key: 'Artists', value: 'artists' },
	{ key: 'Articles', value: 'articles' },
	{ key: 'NewReleases', value: 'new-releases' },
	{ key: 'Podcasts', value: 'podcasts' },
]

export const useFindPageEndpoint = (endpoint) => {
	return pages.find(item => item.key === endpoint)?.value
}